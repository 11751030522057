
  import { defineComponent, ref } from "vue";
  import { calendarFormat } from "@/utils/dateFormat";
  import list from "@/components/common/list.vue";
  import PrizeItem from "@/components/common/PrizeItem.vue";
  export default defineComponent({
    name: "Prize",
    components:{
      list,
      PrizeItem
    },
    setup() {

      return {
        calendarFormat,
      };
    },
  });
