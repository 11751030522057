<template>
  <transition-group tag="ul" name="list" class="notice-wapper notice-ul">
    <li v-for="(item, index) in prizeData" :key="index">
      <div class="spend-date" v-if="item.jgetdate">
        <span>{{ calendarFormat(item.jgetdate)[2] }}</span>
        <p>
          {{ calendarFormat(item.jgetdate)[0] }}/{{ calendarFormat(item.jgetdate)[1] }}
        </p>
      </div>

      <div class="notice-content">奖项：{{ item.jname }}</div>
      <div class="notice-content">奖品：{{ item.jiangpin }}</div>
      <div class="notice-content">
        状态： {{ item.jsenddate ? "已兑换" : "未兑换" }}
      </div>
      <div class="notice-content">
        <!-- 快递信息： -->
        <!-- {{ item.jpost && item.jmail ? item.jpost / item.jmail : "暂无" }} -->
        <span v-if="item.jpost && item.jmail">
          <span class="order-logistics" @click="neiapplyExpressTrace(item.jpost,item.jmail, index)">查看物流</span>
        </span>
        <span v-else>快递信息：暂无</span>
      </div>
      <div class="logistics" v-if="item.traces">
        <p v-if="item.traces.reason">
          <span class="logistics-notyet">{{ item.traces.reason }}</span>
        </p>

        <div v-else class="nei-order-wuliu">
          <ul v-if="item.traces">
            <li v-for="(itm, i) in item.traces.traces" :key="i">
              <span class="orderDetail_accept">
                {{ itm.acceptTime.substring(0,16)}}
                {{ itm.acceptStation }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </transition-group>
</template>



<script lang="ts">
import { defineComponent, onMounted, inject, watch, ref, reactive } from "vue";
import dateFormat from "../../utils/dateFormat";
import { calendarFormat} from "@/utils/dateFormat";
import Toast from "@/components/UI/Toast";
import axios from "@/api/axios";


export default defineComponent({
  name: "InvoiceItem",
  props: {
    prize: Object
  },
  
  setup(props) {
    const prizeData = reactive((props.prize as any).data || [])
    function neiapplyExpressTrace(piaopost: string,piaomail: string, index: any){
      const formData = new FormData();
      formData.append("piaopost", piaopost.valueOf());
      formData.append("piaomail", piaomail.valueOf());
      axios
        .post('/M/Server/neiApplyExpressTrace', formData, {params: { validate: true }})
        .then((res) => {
          if (res.data.success) {
            prizeData[index].traces = res.data.obj
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          Toast({
            type: "error",
            title: "系统出错，请联系管理员!",
          });
        });
    }


    return {
      dateFormat,
      calendarFormat,
      neiapplyExpressTrace,
      prizeData
    };
  }
});
</script>


<style lang="scss" scoped>
main {
  padding-bottom: 110px;
  // background-color: #f4f4f4;
}

.notice-wapper {
  // width: 355px;
  margin: 0 auto;
  li {
    padding: 15px 10px;
    box-sizing: border-box;
    position: relative;

    // padding-left: 70px;
    // border-bottom: 6px solid #fff;
    padding-left: 80px;
    border-bottom: 6px solid #f4f4f4;

    .notice-date {
      color: #999999;
      font-size: 14px;
    }

    .notice-content {
      font-size: 15px;
      line-height: 25px;
      color: #444444;
      margin-top: 7.5px;
    }

    .order-ul {
      li {
        box-sizing: border-box;
        padding: 0 15px;
        padding-bottom: 15px;
        overflow: hidden;
        border-bottom: 1px solid #f4f4f4;
        position: relative;
        padding-top: 15px;
      }
    }
    .spend-date {
      position: absolute;
      // left: 7.5px;
      // top: 24px;
      left: 17px;
      top: 51px;
      color: #666;
      span {
        font-size: 22px;
        text-align: center;
        display: block;
      }
      p {
        font-size: 13.95px;
        border-top: 1px solid #e5e5e5;
        padding-top: 5px;
      }
    }
    .price {
      position: absolute;
      right: 0;
      top: 0;
      height: 40.012px;
      line-height: 40.012px;
      color: rgb(102, 102, 102);
    }
    .order-date {
      margin: 0 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 5px 0 5px 0;
      padding-left: 10px;
      font-size: 16.5px;
      box-sizing: border-box;
      color: #666;
      position: relative;
    }
    .order-btn {
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -11.25px;
      text-align: center;
      width: 80px;
      height: 22.5px;
      border-radius: 15px;

      line-height: 22.5px;

      font-size: 14px;
      color: #df0034;
      border: solid 1px #df0024;
    }
  }
}
.order-logistics{
  display: block;
  width: 80px;
  height: 25px;
  background: #ffeded;
  text-align: center;
  color: #df0024;
  line-height: 25px;
  /* float: right; */
  margin-top: 10px;
  border: 1px solid #df0024;
  border-radius: 20px;
  font-size: 14px;
}
.logistics-notyet{
  font-size: 16px!important;
  color:#df0024;
}
.nei-order-wuliu ul li{
  line-height: 20px;
}
.orderDetail_accept {
  font-size: 14px;
  color: #999;
}
</style>
